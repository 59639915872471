<template>
    <div class="iCountUp">
        <ICountUp
                :delay="delay"
                :endVal="endVal"
                :options="options"
                @ready="onReady"
        />
    </div>
</template>

<script type="text/babel">
    import ICountUp from 'vue-countup-v2';
    export default {
        name: 'countUpNumber',
        components: {
            ICountUp
        },
        data() {
            return {
                delay: 1,
                endVal: 48750000,
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: '',
                    startVal:48750000,
                },
                currentVal: 48750000,
            };
        },
        methods: {
            onReady: function(instance, CountUp) {
                const that = this;
                that.endVal = that.endVal  + this.getRandomNumber()
                instance.update( that.endVal );
            },
            getRandomNumber(){
                return Math.floor(Math.random() * 10000)
            }
        },
        created() {
            //1697841463682
            let startNumber = new Date().getTime() /100000000;
            //console.log(startNumber)
        }
    };
</script>

<style scoped>
    .iCountUp {
        margin: 0;
        color: #4d63bc;
    }
</style>