<template>
  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp :display-position="sportsConst.GAME_DISPLAYPOSITION_1x2"></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="main">
        <div class="main_img" style="background: transparent">
          <img src="../../assets/images/main/balam/main01.png" class="pc" alt="">
          <img src="../../assets/images/main/balam/main01_mobile.png" class="mobile" alt="">
        </div>

        <div class="sub_section">
          <div class="subimg pc" @click="showSportsPanel">
            <img src="../../assets/images/main/balam/01.png" alt="">
          </div>
          <router-link tag="div" to="/casino" class="subimg pc">
            <img src="../../assets/images/main/balam/02.png" alt="">
          </router-link>
          <router-link tag="div" to="/leisure/eospowerball1m" class="subimg pc">
            <img src="../../assets/images/main/balam/03.png" alt="">
          </router-link>
          <div class="subimg mobile" @click="showSportsPanel">
            <img src="../../assets/images/main/balam/m01.jpg" class="" alt="">
          </div>
          <router-link tag="div" to="/casino" class="subimg mobile">
            <img src="../../assets/images/main/balam/m02.jpg"  alt="">
          </router-link>
          <router-link tag="div" to="/leisure/eospowerball1m" class="subimg mobile">
            <img src="../../assets/images/main/balam/m03.jpg"  alt="">
          </router-link>
        </div>
      </div>



    </div>
    <!-- right 메뉴 -->
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <!--배팅카트-->
      <sports-bet-cart-comp slot="betcart" :display-position="displayPosition"></sports-bet-cart-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>
    <foot-comp></foot-comp>

  </div>


</template>

<script>
import RightBarComp from "../../components/RightBarComp";
import TopbarComp from "../../components/TopbarComp";
import {swiper, swiperSlide} from "vue-awesome-swiper"
import "swiper/dist/css/swiper.css";
import FootComp from "../../components/FootComp";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import LeftBarComp from "../../components/LeftBarComp";
import sportsConst from "../../common/sportsConst";
import inplayConst from "../../common/inplayConst";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
import {getSportsInplayGames} from "../../network/sportsInplayRequest";
import {WEBSOCKET_URL} from "../../common/const";
import {exchangeTop10, getEvent, getNoticeList} from "../../network/userRequest";
import {postionMixin} from "../../common/mixin";
import MobileMenuItemsComp from "../../components/MobileMenuItemsComp";
import UserInfoComp from "../../components/UserInfoComp";
import RightButtonsComp from "../../components/RightButtonsComp";
import SportsMainShowComp from "../../components/sports/SportsMainShowComp";
import {getRecentlyGames} from "../../network/sportsRequest";
import NumberScrollComp from "../../components/NumberScrollComp";
import NumberGrow from "../../components/NumberScrollComp";
import CountTo from "../../components/NumberScrollComp";
import CountUpNumber from "../../components/NumberScrollComp";

export default {
  name: "Main",
  mixins: [postionMixin],
  components: {
    CountUpNumber,
    CountTo,
    NumberGrow,
    NumberScrollComp,
    SportsMainShowComp,
    RightButtonsComp,
    UserInfoComp,
    MobileMenuItemsComp,
    SportsBetCartComp,
    RightBarBannerComp,
    LeftBarComp, SportsLeftBarComp, FootComp, TopbarComp, RightBarComp, swiper, swiperSlide
  },
  data() {
    return {
      eventList: [],
      noticeList: [],
      exchangetop10: [],
      noticePageSize: 100,
      inplayList: [],
      wsData: null,
      websocket: null,
      path: WEBSOCKET_URL,
      position: "메인",
      sportsConst,
      inplayConst,
      displayPosition: this.$store.state.displayPostion,
      sports: [],
      inplay: [],
      swiperOption: {
        pagination: {el: '.swiper-pagination'},
        autoplay: {},
        disableOnInteraction: false,
        delay: 2000,

      },
    }
  },
  methods: {
    gotoSports() {
      this.$router.push('/sports')
    },
    showSportsPanel() {
      this.$store.state.isShowLoginPanel = false;
      this.$store.state.isShowRegisterPanel = false;
      this.$store.state.isShowNotMemberCustomerePanel = false;
      this.$store.state.isShowLeisureSelectPanel = false;
      this.$store.state.isShowSportsSelectPanel = true
    },
    showLeisurePanel() {
      this.$store.state.isShowLoginPanel = false;
      this.$store.state.isShowRegisterPanel = false;
      this.$store.state.isShowNotMemberCustomerePanel = false;
      this.$store.state.isShowSportsSelectPanel = false;
      this.$store.state.isShowLeisureSelectPanel = true;
    },
  },
  created() {
    exchangeTop10().then(res => {
      if (res.data.success) {
        this.exchangetop10 = res.data.data
      }
    })
    getNoticeList(this.noticePageSize).then(res => {
      if (res.data.success) {
        this.noticeList = res.data.data
      }
    })
    getEvent(0, this.noticeList).then(res => {
      if (res.data.success) {
        this.eventList = res.data.data
      }
    })

  }
}
</script>
<style scoped>
@import url("../../assets/css/base.css");
@import url("../../assets/css/common.css");
@import url("../../assets/css/style.css");
@import url("../../assets/css/media.css");
@import url("../../assets/css/sports.css");

.content_section {
  width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
}

.content_section .main_img img{
  width: 100%;
}

.content_section .sub_section {
  width: 100%;
  background-color: #000000;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.content_section .sub_section .subimg {
  width: 100%;
  margin: 2px 0;
}
.content_section .sub_section .subimg img{
  width: 100%;
}


.left_section {
  display: none;
}

.right_section {
  display: none;
}

.mobile {
  display: none !important;
}
@media screen and (max-width: 1024px) {
  .pc {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

}


</style>
